.cross-button {
  position: fixed;
  z-index: var(--z-index-above-modal);
  top: var(--ye-spacing-rem-normal);
  right: var(--ye-spacing-rem-normal);

  color: var(--color-canvas);
}

.overlay {
  position: fixed;
  z-index: var(--z-index-ant-modal);
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgb(0 0 0 / 0.5);
}

.video-wrapper {
  position: fixed;
  z-index: var(--z-index-above-modal);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: var(--large-section-width);
  height: var(--box-dimension);

  box-shadow: var(--ye-shadow-floating-medium);

  @media (--media-max-sm) {
    width: var(--box-dimension);
    height: var(--container-button-small);
  }
}

.video-player {
  width: var(--full-width);
  height: var(--full-width);
}
