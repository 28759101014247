.card {
  position: relative;
  color: var(--text-fade-white);
  background: var(--color-ink);
  border-radius: var(--ye-radius-normal);

  &::after {
    content: "";

    position: absolute;
    z-index: var(--ye-z-behind);
    top: var(--half-size-dimension);
    left: var(--half-size-dimension);
    translate: -50% -50%;

    width: var(--full-width);
    height: var(--full-width);
    padding: var(--ye-control-outline-border-width);

    background-image: var(--dark-grey-border-gradient);
    border-radius: inherit;
  }
}

.highlighted {
  color: var(--color-canvas);
  background: var(--blue-linear-gradient-simple);
}
