/* App.css */

.main-container {
  display: flex;
  flex-direction: column;
  gap: var(--ye-spacing-rem-xhuge);
  align-items: flex-start;
  justify-content: space-between;

  padding-block: var(--container-padding);

  @media (--media-max-md) {
    gap: var(--ye-spacing-rem-xlarge);
    padding-block: var(--ye-spacing-rem-xhuge);
    padding-inline: var(--ye-spacing-rem-xlarge);
  }
}

.wrapper {
  display: flex;
  gap: var(--ye-spacing-rem-giant);
  align-items: center;
  justify-content: space-between;

  width: 100%;

  @media (--media-max-md) {
    flex-direction: column-reverse;
    gap: 0;
    justify-content: center;
  }
}

.image-wrapper {
  display: flex;
  flex: 1;
}

.image {
  width: 100%;
  height: 100%;
}

.header-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--ye-spacing-rem-normal);
  align-items: start;
  justify-content: center;

  padding-bottom: var(--ye-spacing-rem-xsmall);

  text-align: center;

  @media (--media-max-md) {
    align-items: center;
  }
}

.header {
  margin: 0;
  font-weight: var(--ye-font-weight-semibold);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-xlarge);
  }
}

.info-text {
  max-width: var(--newsletter-title-width);
  margin-block: 0 var(--ye-spacing-rem-large);

  font-size: var(--ye-font-size-large);
  font-weight: var(--ye-font-weight-light);
  line-height: var(--ye-spacing-rem-huge);
  color: var(--text-fade-white);
  text-align: left;

  @media (--media-max-md) {
    padding-inline: var(--ye-spacing-rem-normal);
    font-size: var(--ye-spacing-rem-small);
    line-height: var(--ye-spacing-rem-large);
    text-align: center;
  }
}

.btn-learn-more {
  padding-block: var(--ye-spacing-rem-xsmall);
  color: var(--color-canvas);

  @media (--media-max-md) {
    display: none;
  }
}
