.root-container {
  position: relative;
  z-index: var(--ye-z-base);
  display: flex;

  &::after {
    content: "";

    position: absolute;
    z-index: var(--ye-z-behind);

    display: block;

    width: 100%;
    height: 100%;

    background: var(--dark-blue-linear-gradient);
  }
}

.root {
  position: relative;
  z-index: var(--ye-z-base);

  display: flex;
  gap: var(--ye-spacing-rem-xlarge);
  align-items: center;
  justify-content: space-between;

  @media (--media-max-md) {
    flex-direction: column;
    gap: var(--ye-spacing-rem-xhuge);
    padding-block: var(--ye-spacing-rem-xhuge);
  }
}

/* left box */

.left-box {
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;

  width: var(--full-width);
  padding-right: var(--ye-spacing-rem-normal);

  @media (--media-max-md) {
    align-items: center;
  }
}

.right-box {
  position: relative;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  flex: 2;
  gap: var(--ye-spacing-rem-xsmall);

  margin: auto;
}

.heading {
  margin: 0;
  font-size: var(--ye-font-size-xhuge);
  font-weight: var(--ye-font-weight-bold);
  line-height: var(--ye-line-height-thin);

  @media (--media-max-md) {
    text-align: center;
  }

  @media (--media-max-sm) {
    font-size: var(--ye-font-size-huge);
  }
}

.info-text {
  max-width: var(--newsletter-title-width);

  font-size: var(--ye-font-size-large);
  font-weight: var(--ye-font-weight-light);
  line-height: var(--ye-spacing-rem-xlarge);
  color: var(--text-fade-white);
  text-align: left;

  @media (--media-max-md) {
    font-size: var(--ye-spacing-rem-small);
    text-align: center;
  }
}

.swiper-container {
  width: var(--full-width);
}

.box {
  @mixin focusAndHover {
    color: var(--color-canvas);
    background: var(--blue-linear-gradient-simple);
  }

  display: flex;
  flex-direction: column;
  gap: var(--ye-spacing-rem-xlarge);
  align-items: flex-start;

  height: 100%;
  padding: var(--ye-spacing-rem-xlarge);

  background-color: var(--bg-color);
  border: var(--ye-control-outline-border-width) solid var(--border-color);
  border-radius: var(--ye-radius-large);

  @media (--media-max-sm) {
    gap: 0;
    max-width: var(--box-dimension-large);
    padding: var(--ye-spacing-rem-large);
  }
}

.title {
  margin: 0;
  margin-bottom: var(--ye-spacing-rem-xsmall);
  font-size: var(--ye-font-size-xlarge);
  font-weight: var(--ye-font-weight-semibold);
}

.desc {
  font-size: var(--ye-font-size-normal);
}

.book-call-btn {
  margin-top: var(--ye-spacing-rem-huge);
  padding: var(--ye-spacing-rem-xsmall) var(--ye-spacing-rem-normal);
  color: var(--color-canvas);
}
