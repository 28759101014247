.headline {
  margin-bottom: var(--ye-spacing-rem-normal);
  font-size: var(--ye-font-size-xlarge);
  font-weight: 500;
  text-align: center;

  @media (--media-max-md) {
    font-size: var(--ye-font-size-normal);
  }

  @media (--media-max-sm) {
    margin-bottom: var(--ye-spacing-rem-small);
  }
}

.content {
  margin-bottom: var(--ye-spacing-rem-normal);

  font-size: var(--ye-font-size-normal);
  color: var(--color-blue-c2-d2);
  text-align: justify;
  text-align: center;

  @media (--media-max-md) {
    font-size: var(--ye-font-size-small);
  }

  @media (--media-max-sm) {
    margin-bottom: var(--ye-spacing-rem-small);
    font-size: var(--ye-font-size-xsmall);
  }
}

.name {
  margin-bottom: var(--ye-spacing-rem-tiny);
  font-size: var(--ye-font-size-large);
  font-weight: 600;
  color: var(--color-primary);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-small);
  }
}

.designation {
  font-size: var(--ye-spacing-rem-small);
  color: var(--color-blue-c2-d2);
  text-align: center;

  @media (--media-max-md) {
    font-size: var(--ye-font-size-xsmall);
  }
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: var(--dark-gradient-overlay);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  z-index: var(--z-index-base);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0;
}

.play {
  width: var(--max-icon-height);
  height: var(--max-icon-height);
}
