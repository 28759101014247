.books-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: var(--ye-spacing-rem-xhuge);

  background: var(--blue-linear-gradient-simple);

  @media (--media-max-md) {
    gap: var(--ye-spacing-rem-xlarge);
    align-items: center;

    height: 100%;
    margin: 0 auto;
    padding: var(--ye-spacing-rem-large);
  }
}

.report-container {
  display: flex;
  flex-direction: row;
  gap: var(--ye-spacing-rem-huge);
  padding-block: var(--ye-spacing-rem-huge);
}

.swiper-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--ye-spacing-rem-xhuge) var(--ye-spacing-rem-normal);
}

.container {
  display: flex;
  flex-direction: row;
  gap: var(--ye-spacing-rem-huge);
  align-items: flex-start;

  @media (--media-max-md) {
    align-items: center;
  }
}

.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}

.heading {
  font-size: var(--ye-font-size-xhuge);
  font-weight: var(--ye-font-weight-semibold);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-huge);
    text-align: center;
  }
}

.title {
  margin: 0;
  margin-bottom: var(--ye-spacing-rem-xhuge);

  font-size: var(--ye-font-size-xlarge);
  font-weight: var(--ye-font-weight-semibold);
  line-height: var(--ye-line-height-thin);

  @media (--media-max-md) {
    margin-bottom: 0;
    text-align: center;
  }

  @media (--media-max-sm) {
    font-size: var(--ye-font-size-large);
  }
}

.sub-title {
  display: flex;
  flex: 4;

  width: var(--full-width);
  margin: 0;

  font-size: var(--ye-font-size-large);
  font-weight: var(--ye-font-weight-light);
  color: var(--text-fade-white);
  text-align: left;

  @media (--media-max-sm) {
    font-size: var(--ye-font-size-small);
  }
}

.link {
  font-size: var(--ye-font-size-small);
  color: var(--color-canvas);
}

.img {
  width: 100%;
  height: 100%;
}

.swiper-container::after {
  left: 0%;
}

.swiper-container::before {
  left: 100%;
}

.image-wrapper {
  display: flex;
  flex: 2;
}
