.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: var(--blog-width);
}

.info-btn {
  margin: var(--ye-spacing-rem-tiny);
  padding: var(--ye-spacing-rem-huge);

  text-align: center;

  background: var(--color-canvas);
  border: 1px solid var(--color-primary-c2-l4);
  border-radius: var(--ye-radius-large);
  box-shadow: var(--ye-shadow-floating-medium);
}

.primary-tag,
.category {
  padding: var(--ye-spacing-rem-tiny);

  font-size: var(--ye-font-size-small);
  color: var(--color-canvas);

  background-color: var(--color-primary);
  border-radius: var(--ye-spacing-rem-xsmall);
}

.primary-tag {
  width: fit-content;
  margin-bottom: var(--ye-spacing-rem-xsmall);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-xsmall);
  }
}

.primary-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: var(--ye-spacing-rem-xlarge);
}

.header {
  font-size: var(--ye-font-size-xhuge);
  font-weight: 600;

  @media (--media-max-md) {
    text-align: center;
  }
}

.info-container {
  padding: var(--ye-spacing-rem-small);
  background: var(--color-canvas);
  border-radius: var(--ye-radius-xlarge);

  @media (--media-max-md) {
    grid-template-columns: 1fr;
  }
}

.title {
  margin: var(--ye-spacing-rem-small) 0 var(--ye-spacing-rem-xsmall);

  font-size: var(--ye-font-size-xlarge);
  font-weight: 600;
  color: var(--color-ink-l2);
  text-align: center;
}

.error {
  margin-top: var(--ye-spacing-rem-xsmall);
  margin-inline: auto;

  font-size: var(--ye-font-size-small);
  color: var(--color-orange-l1);
  text-align: center;
}

.root {
  color: var(--color-canvas);
  background-color: var(--color-ink);
}
