.image-bg {
  background: var(--blue-image-bg);
  bottom: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transform: translateY(50%);
  z-index: 0;
  border-top-left-radius: 40%;
  border-top-right-radius: 40%;

  @media (--media-max-md) {
    height: 120%;
    border-top-left-radius: var(--ye-radius-circle);
    border-top-right-radius: var(--ye-radius-circle);
    transform: translateY(45%);
  }
}
