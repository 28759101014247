.wrapper {
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--media-max-md) {
    flex-direction: column;
    gap: var(--ye-spacing-rem-huge);
  }
}

.title {
  width: 40%;
  padding-inline: var(--ye-spacing-rem-huge);
  font-weight: var(--ye-font-weight-semibold);
  text-align: left;

  @media (--media-max-md) {
    width: 100%;
    padding-inline: var(--ye-spacing-rem-tiny);
    font-size: var(--ye-font-size-xlarge);
    text-align: center;
  }
}

.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 600px;
  height: 600px;
  margin-inline: var(--ye-spacing-rem-xhuge);

  background: var(--mid-blue-radial-gradient);
  border-radius: var(--ye-radius-circle);

  &::before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 70%;
    height: 70%;

    background: var(--color-ink);
    border-radius: var(--ye-radius-circle);
  }

  @media (--media-max-md) {
    width: 325px;
    height: 325px;
  }
}

.footer {
  position: absolute;
  top: 45%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 90px;
  height: 90px;

  &:nth-child(1) {
    transform: rotate(-45deg) translate(300px) rotate(45deg);

    @media (--media-max-md) {
      transform: rotate(-35deg) translate(-150px) rotate(35deg);
    }
  }

  &:nth-child(2) {
    transform: rotate(-135deg) translate(300px) rotate(135deg);

    @media (--media-max-md) {
      transform: rotate(-90deg) translate(123px, 124px) rotate(90deg);
    }
  }

  &:nth-child(3) {
    transform: rotate(405deg) translate(300px) rotate(-405deg);

    @media (--media-max-md) {
      transform: rotate(90deg) translate(-119px, 119px) rotate(-90deg);
    }
  }

  &:nth-child(4) {
    transform: rotate(135deg) translate(300px) rotate(-135deg);

    @media (--media-max-md) {
      transform: rotate(180deg) translate(-125px, -88px) rotate(-180deg);
    }
  }

  &:nth-child(5) {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 160px;
    height: 160px;

    @media (--media-max-md) {
      width: 80px;
      height: auto;
    }
  }

  &:nth-child(6) {
    transform: rotate(270deg) translate(300px) rotate(-270deg);

    @media (--media-max-md) {
      transform: rotate(270deg) translate(162px) rotate(-270deg);
    }
  }

  @media (--media-max-md) {
    width: 60px;
    height: 60px;
  }
}

.investor {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* stylelint-disable-next-line a11y/media-prefers-reduced-motion */
.investor-button {
  @mixin focusAndHover {
    transform: scale(1.1);

    /* stylelint-disable-next-line a11y/media-prefers-reduced-motion */
    &::after {
      content: "";

      position: absolute;
      z-index: var(--ye-z-behind);
      inset: 0;

      background: linear-gradient(90deg, #fff, #81d3ff, #fff);
      filter: blur(2px);
      border-radius: var(--ye-radius-circle);

      animation: rotate 5s ease infinite;
    }
  }

  z-index: var(--ye-z-topnav);

  overflow: visible;

  padding: var(--ye-spacing-rem-xsmall);

  border-radius: var(--ye-radius-circle);

  transition: all 200ms ease-in-out;
}

.inveser-button-no-effect {
  @mixin focusAndHover {
    transform: none;

    &::after {
      display: none;
    }
  }

  padding: 0;
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}

.footer-title {
  @media (--media-max-sm) {
    font-size: var(--ye-font-size-normal);
  }
}

.footer-designation {
  font-size: var(--ye-font-size-small);
  font-weight: var(--ye-font-weight-thin);
  color: var(--text-fade-white);
  text-align: center;

  @media (--media-max-sm) {
    font-size: var(--ye-font-size-xsmall);
  }
}

.gradient-title {
  padding: 0;
}
