.root-container {
  position: relative;
  z-index: var(--ye-z-base);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: var(--container-padding);

  color: var(--color-canvas);

  background-color: var(--color-ink);

  &::after {
    content: "";

    position: absolute;
    z-index: var(--ye-z-behind);

    display: block;

    width: var(--full-width);
    height: var(--full-width);

    background: var(--dark-blue-linear-gradient);
  }

  & :global .swiper-button-next,
  :global .swiper-button-prev {
    position: absolute;

    width: 0;

    color: var(--color-ink-l2);

    background-color: transparent;
    box-shadow: none;
  }

  & :global .swiper-button-next {
    right: 12px;
  }

  & :global .swiper-button-prev {
    left: 8px;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: var(--ye-spacing-rem-xlarge);

  margin-top: 0;
  padding-block: var(--ye-spacing-rem-large);

  &::after {
    content: "";

    display: block;

    width: var(--full-width);
    height: 1px;

    background: var(--mid-blue-linear-gradient);
  }
}

/* top container */

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ye-spacing-rem-xlarge);
  align-items: center;
  justify-content: center;

  @media (--media-max-md) {
    flex-direction: column;
  }
}

/* top container left */

.left-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--ye-spacing-rem-huge);

  max-width: var(--newsletter-title-width);

  @media (--media-max-lg) {
    align-items: center;
  }

  @media (--media-max-md) {
    gap: 0;
    align-items: center;
  }
}

.title {
  margin-bottom: 0;
  line-height: var(--ye-line-height-thin);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-huge);
    text-align: center;
  }
}

.info-text {
  display: flex;
  gap: var(--ye-spacing-rem-large);
  align-items: center;
  justify-content: start;

  max-width: var(--bannner-image-width);
  margin-bottom: var(--ye-spacing-rem-huge);

  font-size: var(--ye-font-size-large);
  font-weight: var(--ye-font-weight-semibold);
  line-height: var(--ye-spacing-rem-xlarge);
  color: var(--text-fade-white);
  text-align: left;
  white-space: nowrap;

  @media (--media-max-md) {
    gap: var(--ye-spacing-rem-small);
    justify-content: center;

    width: var(--full-width);
    max-width: var(--container-button-small);
    margin: 0;
    margin-top: var(--ye-spacing-rem-large);

    font-size: var(--ye-font-size-small);
    text-align: center;
  }
}

.book-call-btn {
  padding: var(--ye-spacing-rem-xsmall) var(--ye-spacing-rem-xlarge);
}

.right-container {
  position: relative;
  display: flex;
  align-items: center;
}

.banner-image-container {
  position: relative;
  width: var(--banner-image);
  height: auto;

  @media (--media-max-md) {
    width: var(--full-width);
  }
}

.banner-image {
  width: var(--full-width);
  height: var(--full-width);
}

.landing-btn-container {
  display: flex;
  gap: var(--ye-spacing-rem-huge);
  align-items: center;

  @media (--media-max-md) {
    gap: var(--ye-spacing-rem-large);
    justify-content: center;
    margin-top: var(--ye-spacing-rem-huge);
    font-size: var(--ye-font-size-small);
  }
}

.number-cards-grid {
  display: grid;
  grid-gap: var(--ye-spacing-rem-xlarge);
  grid-template-columns: repeat(2, 1fr);

  width: 100%;
  padding-bottom: var(--ye-spacing-rem-xhuge);
  padding-inline: var(--ye-spacing-rem-huge);

  @media (--media-max-md) {
    grid-gap: var(--ye-spacing-rem-large);
  }
}

.number-card-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;

  width: var(--full-width);
  height: var(--full-width);
  max-height: max-content;
  padding: var(--ye-spacing-rem-giant);

  background: var(--color-numbers-card);

  @media (--media-max-md) {
    padding: var(--ye-spacing-rem-huge) var(--ye-spacing-rem-small);
  }
}

/* Remove ::after content for light theme */
[data-theme="light"] .number-card-wrapper::after {
  content: none;
  display: none;
}

.number-card {
  display: flex;
  flex-direction: column;
  gap: var(--ye-spacing-rem-normal);
  align-items: start;
  justify-content: center;

  width: auto;

  color: var(--primary-text-color);
  text-wrap: nowrap;

  @media (--media-max-md) {
    align-items: center;
  }
}

.number {
  margin: 0;
  font-size: var(--ye-font-size-xlarge);
  font-weight: var(--ye-font-weight-bold);
  text-align: center;
}

.card-name {
  margin: 0;
  font-size: var(--ye-font-size-normal);
  font-weight: var(--ye-font-weight-light);

  @media (--media-max-sm) {
    font-size: var(--ye-font-size-small);
  }
}

.center-title-container {
  margin-bottom: 0;
}

.center-title {
  margin: 0;
  font-size: var(--ye-font-size-huge);
  font-weight: var(--ye-font-weight-semibold);
  color: var(--primary-text-color);

  @media (--media-max-sm) {
    font-size: var(--ye-font-size-xlarge);
    text-align: center;
  }
}

.statics-container {
  display: flex;
  gap: var(--ye-spacing-rem-huge);
  align-items: center;

  padding-top: var(--ye-spacing-rem-xhuge);
  padding-inline: var(--ye-spacing-rem-xhuge);

  @media (--media-max-sm) {
    flex-direction: column;
  }
}

.vertical-divider {
  width: 2px;
  height: var(--full-width);
  margin-inline: var(--ye-spacing-rem-xhuge);

  opacity: var(--mix-opacity-2);
  background-color: var(--color-light-blue-divider);
}

.data-section {
  position: relative;
}

.card-center {
  display: flex;
  align-items: center;
}

.fund-tag {
  display: flex;
  gap: var(--ye-spacing-rem-small);
  align-items: center;

  color: var(--color-canvas);
  text-wrap: nowrap;

  @media (--media-max-sm) {
    gap: var(--ye-spacing-rem-normal);
  }
}

.dot-color {
  width: var(--ye-spacing-rem-xsmall);
  height: var(--ye-spacing-rem-xsmall);
  background-color: var(--color-canvas);
}
