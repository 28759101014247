.root {
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: center;
  padding-block: var(--ye-spacing-rem-xhuge) var(--ye-spacing-rem-normal);

  @media (--media-max-lg) {
    flex-direction: column-reverse;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: var(--color-ink);
}

.container {
  position: relative;
  z-index: var(--ye-z-base);

  display: flex;
  align-items: end;
  justify-content: space-around;

  margin-block: var(--ye-spacing-rem-xhuge);
  padding-top: var(--ye-spacing-rem-xhuge);
  padding-inline: var(--ye-spacing-rem-xhuge);

  background: var(--dark-blue-45-gradient);
  border-radius: var(--ye-radius-large);

  @media (--media-max-lg) {
    flex-direction: column;
    align-items: center;
    padding-inline: var(--ye-spacing-rem-xlarge);
  }
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin-block: var(--ye-spacing-rem-xhuge);
  padding-top: var(--section-spacing);

  @media (--media-max-lg) {
    align-items: center;
    justify-content: center;
    margin-block: var(--ye-spacing-rem-small);
    text-align: center;
  }
}

.right-container {
  position: relative;
  height: auto;

  & img {
    position: absolute;
    z-index: var(--ye-z-base);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: auto;
    height: var(--blog-banner-height);

    @media (--media-max-lg) {
      width: auto;
      height: 100%;
    }

    @media (--media-max-md) {
      width: 100%;
      height: auto;
      padding-inline: var(--ye-spacing-rem-normal);
    }
  }

  @media (--media-max-md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.image-bg {
  position: relative;
  z-index: 0;
  bottom: 0;

  width: 400px;
  height: 300px;

  background: var(--blue-image-bg);
  border-top-left-radius: var(--ye-radius-circle);
  border-top-right-radius: var(--ye-radius-circle);

  @media (--media-max-md) {
    width: 300px;
    margin-top: var(--ye-spacing-rem-xhuge);
    border-top-left-radius: var(--ye-radius-circle);
    border-top-right-radius: var(--ye-radius-circle);
  }
}

.heading {
  max-width: var(--newsletter-title-width);
  margin-bottom: 0;

  font-size: var(--ye-spacing-rem-huge);
  font-weight: var(--ye-font-weight-semibold);
  line-height: var(--ye-line-height-thin);
  color: var(--darker-blue);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-xlarge);
    text-align: center;
  }
}

.desc {
  max-width: var(--desc-width);
  margin-block: var(--ye-spacing-rem-normal);
  padding-right: var(--toggle-width);

  font-size: var(--ye-font-size-large);
  font-weight: var(--ye-font-weight-light);
  color: var(--text-fade-white);
  text-align: left;

  @media (--media-max-md) {
    padding-right: 0;
    text-align: center;
  }
}

.email-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--ye-spacing-rem-huge);

  @media (--media-max-md) {
    margin-bottom: var(--ye-spacing-rem-xhuge);
  }
}

.email-input {
  margin-bottom: 0;
  font-size: var(--ye-font-size-small);
}

.input {
  margin-bottom: 0;
  padding: var(--ye-spacing-rem-xsmall) var(--ye-spacing-rem-xlarge);
  padding-left: var(--ye-spacing-rem-xlarge);

  background: var(--color-email-inut);
  border: 2px solid var(--color-canvas);
  border-radius: var(--regular-icon-dimensions);

  &::placeholder {
    color: var(--color-canvas);
  }
}

.label {
  padding-left: var(--ye-spacing-rem-small);

  [data-theme="dark"] & {
    color: var(--color-canvas);
  }
}

.join-button {
  flex: 1;
  gap: 0;
  justify-content: center;

  width: var(--box-dimension-small);
  min-width: var(--container-small);
  padding: var(--ye-spacing-rem-small) var(--ye-spacing-rem-xlarge);

  font-size: var(--ye-font-size-small);
  line-height: var(--ye-line-height-thin);
  color: var(--color-canvas);

  background: var(--color-primary-gradient);

  @media (--media-max-sm) {
    gap: var(--ye-spacing-rem-xsmall);
    width: var(--full-width);
    padding: var(--ye-button-padding);
    font-size: var(--ye-font-size-normal);
  }
}

.error-msg {
  margin-top: var(--ye-spacing-rem-normal);
  color: var(--color-negative);
  text-align: center;
}

.input-container {
  display: flex;
  gap: var(--ye-spacing-rem-normal);
  align-items: flex-start;
  justify-content: center;

  @media (--media-max-md) {
    flex-direction: column;
    align-items: center;
  }
}

.ribbon-wrapper {
  --ribbon-corner-size: 0.5rem;
  --ribbon-notch-size: 1.5rem;

  position: absolute;
  top: var(--ye-spacing-rem-xhuge);
  left: calc(-1 * var(--ribbon-corner-size));
  filter: drop-shadow(var(--ye-shadow-neu));
}

.ribbon {
  margin: 0;
  padding-block: var(--ye-spacing-rem-normal);
  padding-inline: var(--ye-spacing-rem-large);

  font-size: var(--ye-spacing-rem-large);
  font-weight: var(--ye-font-weight-semibold);
  line-height: var(--ye-line-height-thin);
  color: var(--color-ribbon-text);

  background: var(--color-ribbon);
  clip-path: polygon(
    0 0,
    0 calc(100% - var(--ribbon-corner-size)),
    var(--ribbon-corner-size) 100%,
    var(--ribbon-corner-size) calc(100% - var(--ribbon-corner-size)),
    100% calc(100% - var(--ribbon-corner-size)),
    calc(100% - var(--ribbon-notch-size))
      calc(50% - var(--ribbon-corner-size) / 2),
    100% 0
  );
  border-right: var(--ribbon-notch-size) solid #0000;
  border-bottom: var(--ribbon-corner-size) solid #0005;

  @media (--media-max-md) {
    font-size: var(--ye-font-size-normal);
  }
}

.enter-email-text {
  margin-bottom: var(--ye-spacing-rem-small);
}

.input-box {
  flex: 3;
}
