.root {
  position: relative;
  z-index: var(--ye-z-base);
  overflow: hidden;
}

.description {
  padding-bottom: var(--ye-spacing-rem-huge);
  padding-inline: 0 var(--ye-spacing-rem-huge);

  font-size: var(--ye-font-size-xlarge);
  font-weight: var(--ye-font-weight-light);
  line-height: var(--ye-font-size-huge);
  color: var(--text-fade-white);
  text-align: left;

  @media (--media-max-md) {
    font-size: var(--ye-font-weight-normal);
    line-height: var(--ye-font-size-large);
  }
}

.know-more-btn {
  font-size: var(--ye-font-size-small);
}

.wrapper {
  background: var(--dark-blue-45-gradient);

  @media (--media-max-md) {
    background: none;
  }
}

.top-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--ye-spacing-rem-normal);
  align-items: center;

  padding-top: 0;
  padding-bottom: 0;

  @media (--media-max-md) {
    grid-template-columns: auto;
    gap: var(--ye-spacing-rem-xlarge);
    background: none;
  }

  @media (--media-max-lg) {
    gap: var(--ye-spacing-rem-xhuge);
  }
}

.container {
  position: relative;
  padding-inline: var(--ye-spacing-rem-normal);
}

.left-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-inline: var(--ye-spacing-rem-xhuge);

  & img {
    @media (--media-max-md) {
      width: 50%;
      height: auto;
    }
  }

  @media (--media-max-md) {
    display: none;
    height: var(--min-card-height);
  }
}

.right-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;

  @media (--media-max-md) {
    width: 100%;
    padding: var(--ye-spacing-rem-huge);
    background: var(--dark-blue-45-gradient);
  }

  @media (--media-max-sm) {
    padding: var(--ye-spacing-rem-xlarge);
  }
}

.girl-image {
  position: relative;
  z-index: var(--ye-z-base);
  width: 100%;
  height: auto;
}

.slider {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 380px;

  @media (--media-max-md) {
    top: 25%;
    right: 0;
    left: 50%;
    transform: translateX(-10%);
  }
}

.slide-title-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: var(--ye-spacing-rem-xhuge) var(--half-size-dimension);
  justify-content: space-evenly;

  width: var(--full-width);
  padding-block: var(--ye-spacing-rem-xhuge);

  @media (--media-max-md) {
    display: flex;
    flex-direction: column;
    gap: var(--ye-spacing-rem-small);

    width: 50%;
    padding-block: 0;
    padding-inline: 0;
  }
}

.slide-title-button {
  all: unset;
}

.slide-title {
  cursor: pointer;

  margin-bottom: var(--ye-spacing-rem-tiny);
  padding-block: 0;
  padding-inline: var(--ye-spacing-rem-normal);

  font-size: var(--ye-font-size-huge);
  font-weight: var(--ye-font-weight-normal);
  line-height: var(--ye-line-height-thin);
  color: var(--color-dark-grey);
  text-align: left;

  transition: color 0.1s ease-in-out;

  &.active {
    font-weight: var(--ye-font-weight-semibold);
    color: transparent;
  }

  &:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  &:nth-child(3) {
    grid-column: 2;
    grid-row: 1;
  }

  @media (--media-max-md) {
    padding: var(--ye-spacing-rem-small);
    font-size: var(--ye-font-size-normal);
    line-height: var(--ye-font-size-normal);
  }
  /* stylelint-disable-next-line csstools/media-use-custom-media */
  @media screen and (prefers-reduced-motion: reduce) {
    cursor: pointer;

    padding: var(--ye-spacing-rem-normal);

    font-size: var(--ye-font-size-huge);
    font-weight: var(--ye-font-weight-normal);
    line-height: var(--ye-line-height-thin);
    color: var(--color-dark-grey);
    text-align: left;

    transition: none;

    @media (--media-max-md) {
      padding: var(--ye-spacing-rem-small);
      font-size: var(--ye-font-size-normal);
      line-height: var(--ye-font-size-normal);
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: fit-content;
  height: 100%;
}

.image-container {
  height: 100%;

  @media (--media-max-md) {
    margin-bottom: calc(-1 * var(--ye-spacing-rem-huge));
  }
}

.image {
  width: auto;
  height: 100%;

  @media (--media-max-md) {
    height: 382px;
  }
}
