/* jscpd:ignore-start */
.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ye-spacing-rem-normal);
  justify-content: flex-start;

  @media (--media-max-md) {
    justify-content: center;
  }
}

.heading-container {
  padding: var(--ye-spacing-rem-xhuge) var(--ye-spacing-rem-normal);
}

.heading {
  margin: 0;
  font-size: var(--ye-font-size-xhuge);
  font-weight: var(--ye-font-weight-semibold);
  line-height: var(--ye-line-height-thin);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-huge);
    text-align: center;
  }
}

.review-card {
  cursor: pointer;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  height: 100%;
  min-height: 285px;
  padding: var(--ye-spacing-rem-normal) var(--ye-spacing-rem-huge);

  color: var(--color-ink);

  background: var(--card-white-color);
  border-radius: var(--ye-spacing-rem-large);

  & h6 {
    margin-block: var(--ye-spacing-rem-xlarge) 0;
    font-size: var(--ye-font-size-large);
    color: var(--color-review-card-headline);

    @media (--media-max-md) {
      font-size: var(--ye-font-size-small);
    }
  }

  & p {
    font-family: var(--ye-font-family-body);
    font-size: var(--ye-font-size-normal);
    font-weight: var(--ye-font-weight-light);
    line-height: var(--ye-spacing-rem-xlarge);
    color: var(--color-review-card-paragraph);
    text-align: left;

    @media (--media-max-md) {
      font-size: var(--ye-font-size-xsmall);
      line-height: var(--ye-spacing-rem-normal);
    }
  }

  @media (--media-max-md) {
    min-height: 192px;
    margin-inline: 0;
    padding: var(--ye-spacing-rem-large);
  }
}

.profile-image-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  border-radius: var(--ye-radius-ellipse);

  @media (--media-max-md) {
    width: 30px;
    height: 30px;
  }
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ye-spacing-rem-normal);
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.user-name-container {
  display: flex;
  gap: var(--ye-spacing-rem-normal);
}

.user-name-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-name {
  margin: 0;
  font-size: var(--ye-font-size-large);
  font-weight: var(--ye-font-weight-semibold);
  color: var(--color-review-card-user);

  @media (--media-max-md) {
    font-size: var(--ye-font-size-xsmall);
    line-height: var(--ye-spacing-rem-normal);
  }
}

.designation {
  color: var(--color-designation);
  text-align: left;
}

.swiper-container {
  width: var(--full-width);
}

.play-btn {
  @media (--media-max-md) {
    padding-inline: var(--ye-spacing-rem-xsmall);
    border-radius: var(--ye-radius-circle);
  }
}

/* jscpd:ignore-end */
